import * as React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import SwiperCore, {Autoplay, Pagination, Navigation} from 'swiper';

import './Carousel.css';

interface LargeImageCarouselProps {
    images: Array<{
        imgSrc: string;
    }>;
}

const LargeImageCarousel: React.FC<LargeImageCarouselProps> = (
    props: LargeImageCarouselProps
) => {
    SwiperCore.use([Autoplay, Pagination, Navigation]);
    return (
        <section className={''}>
            <Swiper
                spaceBetween={50}
                loop={true}
                autoplay={{delay: 5000}}
                slidesPerView={1}
            >
                {
                    props.images.map((img, i) => {
                        return (<SwiperSlide>
                            <div style={{backgroundImage: `url('${img.imgSrc}')`}}
                                 className={'bg-cover bg-top w-full h-[640px] max-md:h-[500px] flex flex-row'}/>

                        </SwiperSlide>)
                    })
                }
            </Swiper>
            <div className={'absolute h-[640px] max-md:h-[400px] top-[103px] z-50 w-full'}>
                <div className={'container flex flex-row mx-auto h-full'}>
                <div className={'lg:w-1/2'}/>
                <div className={'lg:w-1/2 bg-sec p-10 flex flex-col justify-center '}>
                    <h1 className={'text-[46px] max-md:text-2xl text-white font-sans font-bold'}>We’ve been looking for
                        you.</h1>
                    <p className={'font-sans text-white max-md:text-sm text-2xl py-6 mb-8'}>Our employees are truly the soul of our growth and success. Every day we
                        proudly
                        support the growth of our full and part-time employees on their own career
                        journeys. Our recent years of rapid growth make StorageVault an exciting
                        place
                        to plant your feet and grow with us!</p>
                    <a className={'rounded-full max-w-[250px] text-xl hover:text-white hover:opacity-90 focus:opacity-90 text-center bg-pri py-4 px-10 text-white font-sans'} href={"/search/"}>Search Our Jobs</a>
                </div></div>
            </div>
        </section>
    );
}
    ;

    export default LargeImageCarousel;
