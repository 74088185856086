import React from 'react';
import Layout from '../layouts/Layout';
import { graphql, useStaticQuery } from 'gatsby';
import LargeImageCarousel from '../components/LargeImageCarousel/LargeImageCarousel';
import car1 from '../images/car-1.jpg';
import car2 from '../images/car-2.jpg';
import car3 from '../images/car-3.jpg';
import car4 from '../images/car-4.jpg';
import car5 from '../images/car-5.jpg';
import car6 from '../images/car-6.jpg';
import car7 from '../images/car-7.jpg';

import icon1 from '../images/icon-meeting.svg';
import icon2 from '../images/icon-money.svg';
import icon3 from '../images/icon-presentation.svg';

import sentinelLogo from '../images/sentinelonblack.png';
import accessStorageLogo from '../images/access-storage.svg';
import depotiumLogo from '../images/depotium-logo.svg';
import flexLogo from '../images/flex-space-logo.svg';
import cubeitLogo from '../images/cubet-it-logo.svg';
import storageVaultLogo from '../images/storage-vault-lofo.svg';
import recordExpressLogo from '../images/record-express-logo.svg';
import storageVaultCanada from '../images/logo-trans.png';
import moveBuddy from '../images/movebuddy-logo.svg';
import accessResultManagementServices from '../images/ARMSLogo.png';

import banner from '../images/banner.jpg';

import storageBG from '../images/storage-bg.jpg';

import arrowRight from '../images/arrow-right.svg';

import accessStorageVideo from '../images/access-storage-video.mp4';

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);
	const menuItems = [
		{
			text: 'Home',
			href: '/',
			active: false
		}
	];

	const carouselImages = [
		{ imgSrc: car1 },
		{ imgSrc: car3 },
		{ imgSrc: car4 },
		{ imgSrc: car5 },
		{ imgSrc: car6 },
		{ imgSrc: car7 }
	];

	const companies = [
		{
			logo: sentinelLogo,
			logoAlt: 'Sentinel Storage Logo',
			text: 'Secure & Affordable Storage Units with Friendly, Knowledgeable Staff.',
			href: '/search/?brand=Sentinel Storage',
			width: 236
		},
		{
			logo: accessStorageLogo,
			logoAlt: 'Access Storage Logo',
			text: 'Secure Storage Units with Affordable Pricing & Knowledgeable Staff.',
			href: '/search/?brand=Access Storage',
			width: 169
		},
		{
			logo: depotiumLogo,
			logoAlt: 'Depotium Logo',
			text: 'Depotium Mini Entrepôt has over 140 locations nationwide across Canada.',
			href: '/search/?brand=Depotium',
			width: 229
		},
		{
			logo: flexLogo,
			logoAlt: 'FlexSpace Logistics Logo',
			text: "Canada's leading business platform for affordable storage and logistics solutions.",
			href: "/search/?brand=FlexSpace Logistics'",
			width: 220
		},
		{
			logo: cubeitLogo,
			logoAlt: 'Cubeit Logo',
			text: 'Cubeit Portable Storage is a proudly Canadian company that helps with their moving and storage needs.',
			href: '/search/?brand=Cubeit',
			width: 118
		},
		{
			logo: recordExpressLogo,
			logoAlt: 'Record X Press Logistics Logo',
			text: 'Providing record management services including offsite document storage, data protection, document digitizing and more.',
			href: '/search/?brand=RecordXpress',
			width: 253
		},
		{
			logo: storageVaultLogo,
			logoAlt: 'Storage Vault Logo',
			text: 'StorageVault Containers are heavy-duty and designed to provide secure storage on-site for your tools and supplies.',
			href: '/search/?brand=Storage Vault',
			width: 269
		},
		{
			logo: storageVaultCanada,
			logoAlt: 'Storage Vault Canada Logo',
			text: 'Providing record management services including offsite document storage, data protection, document digitizing and more.',
			href: '/search/?brand=Storage Vault Canada',
			width: 269
		},
		{
			logo: moveBuddy,
			logoAlt: 'MoveBuddy',
			text: 'We will connect with you to understand your move. Our priority is to ensure you have an exceptional moving experience. Guaranteed.',
			href: '/search/?brand=Move Buddy',
			width: 150
		},
		{
			logo: accessResultManagementServices,
			logoAlt: 'Access Results Management Services (ARMS)',
			text: 'Providing record management services including offsite document storage, data protection, document digitizing and more.',
			href: '/search/?brand=ARMS',
			width: 200
		}
	];
	return (
		<Layout
			menuItems={menuItems}
			title={'Storage Vault Careers'}
			desc={'Storage Vault Careers'}
		>
			<LargeImageCarousel images={carouselImages} />
			<section className={'w-full bg-[#EEEEEE]'}>
				<div
					className={
						'container mx-auto flex flex-col items-center px-5 pt-12'
					}
				>
					<h2 className={'font-sans text-4xl text-body'}>
						Choose your next role at one of our Brands
					</h2>
				</div>
			</section>
			<section className={'w-full bg-[#EEEEEE]'}>
				<div
					className={
						'container mx-auto flex flex-row flex-wrap items-center justify-center gap-7 px-5 py-12'
					}
				>
					{companies.map((company) => {
						return (
							<div
								className={
									'flex min-h-[350px] w-[358px] flex-col items-center rounded-xl bg-white p-6 shadow'
								}
							>
								<img
									width={company.width}
									src={company.logo}
									className={'my-6'}
									alt={company.logoAlt}
								/>
								<p
									className={
										'my-4 min-h-[110px] text-center font-sans text-lg'
									}
								>
									{company.text}
								</p>
								<a
									className={
										'atext-xl flex flex-row font-sans text-pri'
									}
									href={company.href}
								>
									View Openings{' '}
									<img
										alt={'Right Arrow'}
										className={'ml-3'}
										width={14}
										src={arrowRight}
									/>{' '}
								</a>
							</div>
						);
					})}
				</div>
			</section>
			<section className={'w-full bg-[#EEEEEE] py-6'}>
				<div
					className={
						'container mx-auto flex max-w-[930px] flex-row justify-center px-5'
					}
				>
					<video
						className={'max-h-[600px] w-auto'}
						src={accessStorageVideo}
						title="Access Storage"
						controls
						controlsList="nodownload"
					/>

				</div>
			</section>

			<section className={'w-full bg-[#EEEEEE] py-12'}>
				<div className={'container mx-auto flex flex-col px-5'}>
					<h2 className={'pb-4 font-sans text-4xl text-body'}>
						Why StorageVault?
					</h2>
					<hr className={'border-b-1 border-[#AAAAAA] py-4'} />
					<p className={'font-xl font-sans text-body'}>
						There are several reasons why people would be happy to
						work at StorageVault, a Canadian self-storage company
						that operates more than 200 storage facilities across
						the country.
					</p>
					<span
						className={'font-xl my-4 font-sans font-bold text-body'}
					>
						Here are a few of the top reasons:
					</span>
				</div>
				<div
					className={
						'container mx-auto flex flex-row flex-wrap items-center justify-center gap-7 px-5 pt-6 pb-12'
					}
				>
					<div
						className={
							'flex min-h-[630px] w-[358px] flex-col items-center rounded-xl bg-white p-6 shadow'
						}
					>
						<div className={'my-4 h-32'}>
							<img src={icon1} />
						</div>
						<h2
							className={
								'mb-6 text-center font-sans text-2xl text-body'
							}
						>
							Competitive Pay and Benefits:
						</h2>
						<p
							className={
								'my-4 min-h-[110px] text-center font-sans text-lg'
							}
						>
							StorageVault offers competitive pay and benefits
							packages to its employees, including health and
							dental insurance, retirement savings plans, and
							vacation time. The company is committed to ensuring
							that they are fairly compensated and have access to
							the resources they need to live a healthy and
							fulfilling life.
						</p>
					</div>
					<div
						className={
							'flex min-h-[630px] w-[358px] flex-col items-center rounded-xl bg-white p-6 shadow'
						}
					>
						<div className={'my-4 h-32'}>
							<img src={icon2} />
						</div>
						<h2
							className={
								'mb-6 text-center font-sans text-2xl text-body'
							}
						>
							Positive Work Environment:
						</h2>
						<p
							className={
								'my-4 min-h-[110px] text-center font-sans text-lg'
							}
						>
							StorageVault is known for fostering a positive and
							supportive work environment. The company values
							teamwork, open communication, and a strong work
							ethic. Employees are encouraged to collaborate and
							support each other to achieve common goals.
						</p>
					</div>
					<div
						className={
							'flex min-h-[630px] w-[358px] flex-col items-center rounded-xl bg-white p-6 shadow'
						}
					>
						<div className={'my-4 h-32'}>
							<img src={icon3} />
						</div>
						<h2
							className={
								'mb-6 text-center font-sans text-2xl text-body'
							}
						>
							Growth and Development:
						</h2>
						<p
							className={
								'my-4 min-h-[110px] text-center font-sans text-lg'
							}
						>
							StorageVault provides its employees with
							opportunities for growth and development, both
							through on-the-job training and professional
							development programs. The company values investing
							in its employees, and offers a variety of training
							and development opportunities to help them advance
							their careers.
						</p>
					</div>
				</div>
				<div className={'container mx-auto mb-12 flex flex-col px-5'}>
					<h2 className={'pb-4 font-sans text-4xl text-body'}>
						Find your Next Job
					</h2>
					<hr className={'border-b-1 border-[#AAAAAA] py-4'} />
					<p className={'font-xl font-sans text-body'}>
						Joining StorageVault means having the opportunity to
						spend time doing more than just a job. It's the
						opportunity to develop your unique skills and
						personality, so together we can become the most trusted
						and reliable name in Canadian self-storage. There are
						endless frontiers yet to be explored, waiting for you to
						grow and pioneer your unique career path.
					</p>
					<span
						className={'font-xl mt-4 font-sans font-bold text-body'}
					>
						We welcome and encourage applications from people with
						disabilities.
					</span>
					<p className={'font-xl font-sans text-body'}>
						Accommodations are available on request for candidates
						taking part in all aspects of the selection process.
					</p>
				</div>
			</section>
			<section>
				<div
					style={{ backgroundImage: `url('${storageBG}')` }}
					className={
						'flex min-h-[600px] w-full flex-row bg-cover bg-top'
					}
				>
					<div className={'container mx-auto flex flex-row'}>
						<div
							className={
								'flex flex-col justify-center bg-sec p-6 md:p-10 lg:w-1/2'
							}
						>
							<h2
								className={
									'font bold mb-4 font-sans text-4xl text-white'
								}
							>
								Our Culture
							</h2>
							<hr className={'border-b-1 border-white'} />
							<p className={'py-6 font-sans text-2xl text-white'}>
								In spite of our individual roles, we are a
								culture that rewards creative problem solving
								and rapid decision making. StorageVault is a
								company full of entrepreneurs, and we are all
								encouraged to be tenacious, agile, and to work
								towards collective goals, but in our own unique
								ways. We move fast and are consistently
								improving and optimizing.
							</p>
						</div>
						<div className={'lg:w-1/2'} />
					</div>
				</div>
			</section>
			<section>
				<img
					src={banner}
					alt={'Employee Banner'}
					className={'my-4 w-full'}
				/>
			</section>
		</Layout>
	);
};

export default IndexPage;
